import { createStore } from 'vuex';

export default createStore({
  state: {
    user: null,
    token: null,
    email_verified: null,
    account_type: null,
  },
  mutations: {
    // setUser sets the user and email_verified properties if applicable
    setUser(state, payload) {
      state.user = payload;
      if (payload != null) {
        state.email_verified = state.user.emailVerified;
      }
    },
    setEmailVerified(state, payload) {
      state.email_verified = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    },
    setAccountType(state, payload) {
      state.account_type = payload;
    },
  },
  actions: {},
  modules: {},
});
