<template>
  <div class="container-fluid">Redirecting..</div>
</template>

<script>
export default {
  data() {
    return {
      mode: null,
      action_code: null,
      continue_url: null,
      language: null,
    };
  },
  created() {
    var self = this;
    console.log(self.$route.query);

    self.mode = self.$route.query.mode;
    self.action_code = self.$route.query.oobCode;
    self.api_key = self.$route.query.apiKey;
    self.continue_url = self.$route.query.continueUrl;
    self.language = self.$route.query.lang || 'en';

    if (self.mode == 'verifyEmail') {
      let redirect_url = `/verify-email?mode=${self.mode}&oobCode=${self.action_code}&apiKey=${self.api_key}&lang=${self.language}`;
      self.$router.push(redirect_url);
    }

    if (self.mode == 'resetPassword') {
      let redirect_url = `/change-pwd?mode=${self.mode}&oobCode=${self.action_code}&apiKey=${self.api_key}&lang=${self.language}`;
      self.$router.push(redirect_url);
    }

    if (self.mode == 'revertSecondFactorAddition') {
      let redirect_url = `/revert-2fa?mode=${self.mode}&oobCode=${self.action_code}&apiKey=${self.api_key}&lang=${self.language}`;
      self.$router.push(redirect_url);
    }
  },
};
</script>

<style></style>
