<template>
  <div id="app">
    <!-- <div id="app" :class="[{ collapsed: collapsed }]"> -->
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/register">Register</router-link>
    </div>-->
    <!-- <Navbar /> -->
    <!-- <NavbarLight /> -->
    <!-- <Navigation :project_name="project_name" v-if="enabled_in_this_page" /> -->
    <!-- <Sidebar
            v-if="enabled_in_this_page"
            :collapsed="collapsed"
            @sidebarCollapsed="onSidebarCollapsed"
    />-->
    <!-- <router-view class="container-fluid" :project_name="project_name" /> -->
    <router-view :project_name="project_name" />
  </div>
</template>

<script>
// import Navbar from './components/Navbar.vue'
// import NavbarLight from './components/NavbarLight.vue'
// import { SidebarMenu } from 'vue-sidebar-menu'

// import Sidebar from "@/components/Sidebar.vue";
// import Navigation from "@/components/Navigation.vue";
// import { auth } from "@/services/firebase";

export default {
  components: {
    // NavbarLight,
    // SidebarMenu,
    // Navigation,
    // Sidebar,
  },
  data() {
    return {
      project_name: 'Benchomatic',

      // collapsed: true,
      enabled_in_this_page: true,
    };
  },
  created() {
    console.log(process.env.VUE_APP_NAME + '@' + process.env.VUE_APP_VERSION + '@' + process.env.VUE_APP_GIT_VERSION);
  },
  methods: {},
};
</script>

<style>
/* #app {
    padding-left: 200px;
}
#app.collapsed {
    padding-left: 50px;
} */

body {
  padding-top: 60px;
}
</style>
