import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

// import Home from "@/views/Home.vue";
// import Experiment from "@/views/Experiment.vue";
// import Auth from "@/views/Auth.vue";

const routes = [
  {
    path: '/manage-account',
    name: 'UserManageAccount',
    component: () => import(/* webpackChunkName: "manage_account" */ '@/views/UserManageAccount.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER ManageAccount:', store.state.user);
      next();
    },
  },

  {
    path: '/verify-email',
    name: 'UserVerifyEmail',
    component: () => import(/* webpackChunkName: "verify_email" */ '@/views/UserVerifyEmail.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER VerifyEmail:', store.state.user);
      next();
    },
  },

  {
    path: '/change-pwd',
    name: 'UserChangePassword',
    component: () => import(/* webpackChunkName: "change_pwd" */ '@/views/UserChangePassword.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER ChangePassword:', store.state.user);
      next();
    },
  },

  {
    path: '/revert-2fa',
    name: 'UserRevertSecondFactor',
    component: () => import(/* webpackChunkName: "revert_2fa" */ '@/views/UserRevertSecondFactor.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER UserRevertSecondFactor:', store.state.user);
      next();
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // mode: "history",
  routes,
});

export default router;
