<template>
  <div class="container mt-5">
    <FirebaseUserRevertSecondFactorAddition />
  </div>
</template>

<script>
import FirebaseUserRevertSecondFactorAddition from '@/components/FirebaseUserRevertSecondFactorAddition';

export default {
  components: {
    FirebaseUserRevertSecondFactorAddition,
  },
};
</script>

<style></style>
