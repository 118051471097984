<template>
  <div class="container mt-5">
    <FirebaseUserChangePassword />
  </div>
</template>

<script>
import FirebaseUserChangePassword from '@/components/FirebaseUserChangePassword';

export default {
  components: {
    FirebaseUserChangePassword,
  },
};
</script>

<style></style>
