import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { auth, onAuthStateChanged } from '@/services/firebase';

import 'bootstrap/dist/css/bootstrap.min.css';

// font awesome
// import { library } from "@fortawesome/fontawesome-svg-core";
// import {
//   faUserSecret,
//   faVials,
//   faSyringe,
//   faFlask,
//   faMicroscope,
//   faRuler,
//   faBiohazard,
//   faAtom,
//   faHeartbeat,
//   faEye,
//   faChartBar,
//   faDna,
//   faTh,
//   faShapes,
//   faLightbulb
// } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// library.add(
//   faUserSecret,
//   faVials,
//   faSyringe,
//   faFlask,
//   faMicroscope,
//   faRuler,
//   faBiohazard,
//   faAtom,
//   faHeartbeat,
//   faEye,
//   faChartBar,
//   faDna,
//   faTh,
//   faShapes,
//   faLightbulb
// );

let app;
onAuthStateChanged(auth, async (user) => {
  // CORE LOGIC (order is important)
  // do not create app, if we dont know auth/unauth state
  // if authed, await the token as well, since it is needed in some views
  // then make app. if this is a subsequent auth state change, ignore this step
  // treat certain routes with redirects, given auth state

  if (user) {
    await user
      .getIdToken(true)
      .then((idToken) => {
        // Send token to your backend via HTTPS
        store.commit('setToken', idToken);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  store.commit('setUser', user);

  if (!app) {
    app = createApp(App);
    app
      .use(store)
      .use(router)
      // .component("font-awesome-icon", FontAwesomeIcon)
      .mount('#app');
  }

  console.log(
    'AUTH_STATE CHANGED:',
    'User=',
    store.state.user,
    'Token=',
    store.state.token,
    'EmailVerified=',
    store.state.email_verified
  );
});
