<template>
  <div class="container">
    <div class="form-group">
      <div class="form-row">
        <div class="col-md-4 offset-md-4">
          <div class="card bg-light">
            <div class="card-body">
              <div class="form-group mb-0 d-grid">
                <span v-html="verification_message"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth, applyActionCode } from '@/services/firebase';
import { appAlertBuilder } from '@/helpers/app.js';

export default {
  data() {
    return {
      mode: null,
      action_code: null,
      continue_url: null,
      language: null,

      verification_message: null,
    };
  },
  created() {
    var self = this;
    console.log(self.$route.query);

    self.mode = self.$route.query.mode;
    self.action_code = self.$route.query.oobCode;
    self.continue_url = self.$route.query.continueUrl;
    self.language = self.$route.query.lang || 'en';

    applyActionCode(auth, self.action_code)
      .then((resp) => {
        // console.log(resp);

        self.verification_message = appAlertBuilder(
          'Your second factor has been reverted successfully. You can now continue using Benchomatic.',
          'success'
        );
      })
      .catch((error) => {
        console.error(error);
        self.verification_message = appAlertBuilder(
          'The verification link you followed is not valid or it has expired.',
          'danger'
        );
      });
  },
};
</script>

<style>
</style>