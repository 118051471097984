<template>
  <div class="container mt-5">
    <FirebaseUserVerifyEmail />
  </div>
</template>

<script>
import FirebaseUserVerifyEmail from '@/components/FirebaseUserVerifyEmail';

export default {
  components: {
    FirebaseUserVerifyEmail,
  },
};
</script>

<style></style>
