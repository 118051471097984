<template>
  <div class="container">
    <div class="form-group">
      <div class="form-row">
        <div class="col-md-4 offset-md-4">
          <div class="card bg-light">
            <div class="card-body">
              <h3 class="font-weight-light mb-3">Change Password</h3>
              <div class="form-group mb-0 d-grid">
                <!-- <small class="text-muted mb-1">Enter your new password.</small> -->
                <section class="form-group mb-3" v-if="!change_completed">
                  <label class="form-control-label sr-only" for="password1">New Password</label>
                  <input class="form-control" type="password" id="password1" placeholder="Enter New Password" required name="password1" v-model="password_1" />
                </section>
                <section class="form-group mb-3" v-if="!change_completed">
                  <label class="form-control-label sr-only" for="password2">Confirm Password</label>
                  <input class="form-control" type="password" id="password2" placeholder="Confirm Password" required name="password2" v-model="password_2" />
                </section>

                <button @click.prevent="trigger_change_pwd" :disabled="change_in_progress" v-if="!change_completed" class="btn btn-primary btn-block mb-3" type="button">
                  <span v-if="change_in_progress" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Change Password
                </button>
                <span v-html="change_status"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth, verifyPasswordResetCode, confirmPasswordReset } from '@/services/firebase';
import { appAlertBuilder } from '@/helpers/app.js';

export default {
  data() {
    return {
      account_email: null,
      password_1: null,
      password_2: null,

      change_in_progress: null,
      change_status: null,
      change_completed: null,
    };
  },

  created() {
    var self = this;
    console.log(self.$route.query);

    self.mode = self.$route.query.mode;
    self.action_code = self.$route.query.oobCode;
    self.api_key = self.$route.query.apiKey;
    self.continue_url = self.$route.query.continueUrl;
    self.language = self.$route.query.lang || 'en';

    verifyPasswordResetCode(auth, self.action_code)
      .then((email) => {
        // console.log(email);
        self.account_email = email;
        self.change_completed = false; // this is NOT terminal state as of yet
      })
      .catch((error) => {
        // Invalid or expired action code. Ask user to try to reset the password again.
        self.change_completed = true; // this is terminal state
        self.change_in_progress = false;
        self.change_status = appAlertBuilder(error, 'danger');
      });
  },

  methods: {
    trigger_change_pwd: function () {
      var self = this;
      self.change_status = null;
      self.change_in_progress = true;

      if (self.password_1 == null || self.password_1 == '') {
        self.change_in_progress = false;
        self.change_status = appAlertBuilder('Missing password.', 'danger');
        return false;
      }

      if (self.password_1 != self.password_2) {
        self.change_in_progress = false;
        self.change_status = appAlertBuilder('Passwords are not the same', 'danger');
        return false;
      }

      confirmPasswordReset(auth, self.action_code, self.password_1)
        .then((resp) => {
          // console.log(resp);

          self.change_completed = true; // to hide the button from resending
          self.change_in_progress = false;
          self.change_status = appAlertBuilder(
            'Your new password has been set successfully. You can now sign in using your new password.',
            'success'
          );

          // Password reset has been confirmed and new password updated.
          // TODO: Display a link back to the app, or sign-in the user directly
          // if the page belongs to the same domain as the app:
          // auth.signInWithEmailAndPassword(accountEmail, newPassword);
          // TODO: If a continue URL is available, display a button which on
          // click redirects the user back to the app via continueUrl with
          // additional state determined from that URL's parameters.
        })
        .catch((error) => {
          // Error occurred during confirmation. The code might have expired or the password is too weak.
          console.error(error);
          self.change_in_progress = false;
          self.change_status = appAlertBuilder(error, 'danger');
        });
    },
  },
};
</script>

<style>
</style>